/* eslint-disable import/no-duplicates */
import moment from 'moment'
import datetime from '@/components/dateTime/datetime.vue'
import confirm from '../../components/confirm/confirm.vue'
export default {
  props: [],
  name: '',
  components: {
    'date-time': datetime,
    confirm: confirm
  },
  data () {
    return {
      loading: true,
      dialog: false,
      selectedAttendance: {},
      isApproved: false,
      isSubmitted: false,
      selectedTimesheetsDate: null,
      isMobile: true,
      sites: [],
      rowsPerPageItems: [20, 50, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }],
      attendanceClone: null,
      pagination: {
        rowsPerPage: 20
      },
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'Check In', value: 'checkInTime' },
        { text: 'Check Out', value: 'checkOutTime' },
        { text: 'Duration', value: null },
        { text: 'Client', value: 'client.name' },
        { text: 'Site', value: 'site.name' },
        { text: 'Comment', value: 'site.comment' },
        { text: 'Metadata 1', value: 'metadata.option1' },
        { text: 'Metadata 2', value: 'metadata.option2' },
        { text: 'Metadata 3', value: 'metadata.option3' },
        { text: 'Actions', value: null }
      ],
      lookups: []
    }
  },
  watch: {
    selectedTimesheetsDate () {
      this.getTimesheet()
      this.isWeekApproved()
      this.isWeekSubmitted()
      this.getApprover()
    }
  },
  computed: {
    allowedDates () {
      const weekDates = this.$store.getters['timesheet/getAllowedDates']
      // Adding (submitted) to date if week is submitted
      // let newWeekDates = ''
      // if (weekDates !== undefined && weekDates.length > 0) {
      //   newWeekDates = this.submittedAllowedDates(weekDates)
      // }
      return weekDates
    },
    employeeTimesheets () {
      return this.$store.getters['timesheet/getEmployeeTimesheets']
    },
    approver () {
      return this.$store.getters['timesheet/getUserTimesheetsApprover']
    },
    isAllApproved () {
      const approvedTimesheet = this.employeeTimesheets.filter(et => et.approved)
      const attendanceTimesheets = this.employeeTimesheets
      if (approvedTimesheet.length === attendanceTimesheets.length) {
        return true
      } else {
        return false
      }
    },
    clients () {
      return this.$store.getters['client/getClients']
    },
    metadataTotals () {
      const totals = {
        option1: 0,
        option2: 0,
        option3: 0
      }

      if (this.employeeTimesheets && this.employeeTimesheets.length) {
        this.employeeTimesheets.forEach((timesheet) => {
          if (timesheet.metadata.option1.enabled) {
            totals.option1 += 1
          }

          if (timesheet.metadata.option2.enabled) {
            totals.option2 += 1
          }

          if (timesheet.metadata.option3.enabled) {
            totals.option3 += 1
          }
        })
      }

      return totals
    },
    /**
   * Retrieving the application configuration for this domain from the session
   * @returns {object} configuration for this application (domain)
   */
    applicationConfiguration () {
      return this.$store.getters.getApplicationConfiguration
    },
    /**
   * Retrieving the 'editableTimeSheet' boolean from the application configuration.
   * This boolean toggles whether or not the edit button for the time sheet
   * will be visible to the user
   * @returns {boolean} defaulted to true if the configuration variable isn't present
   */
    editableTimeSheet () {
      // Check if application configuration is not null and pwa is present
      if (this.applicationConfiguration && this.applicationConfiguration.pwa) {
        // Check if the editableTimeSheet variable is present
        if (Object.prototype.hasOwnProperty.call(this.applicationConfiguration.pwa, 'editableTimeSheet')) {
          // Return editableTimeSheet variable
          return this.applicationConfiguration.pwa.editableTimeSheet
        }
      }
      // Default to true if no configuration variable is found
      return true
    }
  },
  methods: {
    create () {
      const scope = this
      scope.loading = true

      const params = {
        employeeId: scope.selectedAttendance.userId,
        attendance: scope.selectedAttendance
      }

      this.$store.dispatch('timesheet/createAttendance', params).then(function () {
        // Display the success
        scope.$snotify.success('Attendance Created Successfully.', {
          timeout: 3000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
        // Reset the screen
        scope.getTimesheet(false)
        scope.loading = false
        scope.dialog = false
      }).catch(function (err) {
        // If the request errored display it
        scope.loading = false
        scope.$snotify.error(err.message, {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
    },
    update () {
      const scope = this
      scope.loading = true

      const params = {
        employeeId: scope.selectedAttendance.userId,
        attendance: scope.selectedAttendance
      }

      this.$store.dispatch('timesheet/updateAttendance', params).then(function () {
        scope.$snotify.success('Attendance updated successfully.', {
          timeout: 3000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
        scope.getTimesheet(false)
        scope.loading = false
        scope.dialog = false
      }).catch(function (res) {
        scope.loading = false
        scope.$snotify.error(res.message, {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
    },
    save () {
      if (this.$refs.attendanceForm.validate()) {
        if (this.selectedAttendance.id) {
          this.update()
        } else {
          this.create()
        }
      }
    },
    cancel () {
      this.dialog = false
    },
    getClients: function () {
      const scope = this
      //  gets all the clients
      scope.$store.dispatch('client/getClients').then(function (res) {
      }).catch(function (err) {
        scope.errMessage(err)
      })
    },
    clientChanged () {
      const scope = this
      const client = this.clients.find(client => client.id === this.selectedAttendance.clientId)
      // if client exists
      if (client != null) {
        // set sites to the sites on the selected client
        this.sites = client.sites
        // check if there is a site set in clone object
        if (Object.keys(this.selectedAttendance.site).length > 0) {
          // yesh then find the site in the list of selected clients sites
          var selectedSite = scope.sites.find(item => item.id === scope.attendanceClone.site.id)

          if (selectedSite) {
            // set to siteId to on the v-select v-model
            scope.selectedAttendance.siteId = selectedSite.id
          }
        }
      }
    },
    add () {
      const scope = this

      scope.selectedAttendance = {
        checkInTime: undefined,
        checkOutTime: undefined,
        siteId: undefined,
        clientId: undefined,
        metadata: {
          option1: {
            enabled: false,
            id: ''
          },
          option2: {
            enabled: false,
            id: ''
          },
          option3: {
            enabled: false,
            id: ''
          }
        }
      }

      scope.dialogMode = 'add'

      scope.dialog = true
    },
    async submit () {
      const scope = this
      this.loading = true
      const endTime = this.selectedTimesheetsDate
      this.$refs.confirm.open('Submit Timesheet', `For the week ending ${this.selectedTimesheetsDate.slice(0, 10)}`, { color: 'primary', confirm: 'Yes' })
        .then((confirm) => {
          if (confirm) {
            try {
              var params = {
                endDate: endTime
              }
              scope.$store.dispatch('timesheet/submitEmployeeWeekly', params).then(function () {
                scope.$snotify.success('Submitted Timesheet Successfully.', {
                  timeout: 3000,
                  showProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true
                })
                scope.loading = false
                scope.isSubmitted = true
              })
            } catch (err) {
              scope.$snotify.error(err.message, {
                timeout: 3000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true
              })
            }
          }
          scope.loading = false
        })
      scope.loading = false
    },
    async unSubmit () {
      const scope = this
      const endTime = scope.selectedTimesheetsDate
      this.$refs.confirm.open('Unsubmit Timesheet', 'Are you sure?', { color: 'primary', confirm: 'Yes' })
        .then((confirm) => {
          if (confirm) {
            var params = {
              endDate: endTime,
              id: ''
            }
            try {
              scope.$store.dispatch('timesheet/unSubmitEmployeeWeekly', params).then(function () {
                scope.$snotify.success('Unsubmitted Timesheet Successfully.', {
                  timeout: 3000,
                  showProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true
                })
                scope.isSubmitted = false
              }).catch(function (err) {
                scope.$snotify.error(err.message, {
                  timeout: 3000,
                  showProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true
                })
              })
            } catch (err) {
              scope.$snotify.error(err.message, {
                timeout: 3000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true
              })
            }
          }
        })
    },
    edit (attendance) {
      const scope = this

      scope.selectedAttendance = attendance
      scope.attendanceClone = JSON.parse(JSON.stringify(attendance))
      this.clientChanged()

      scope.dialogMode = 'edit'

      scope.dialog = true
    },
    getEndOfWeek () {
      const dayINeed = 5 // for Thursday
      const today = moment().isoWeekday()
      var endOfWeek = null

      // if we haven't yet passed the day of the week that I need:
      if (today <= dayINeed) {
        // then just give me this week's instance of that day
        endOfWeek = moment().isoWeekday(dayINeed)
      } else {
        // otherwise, give me *next week's* instance of that same day
        endOfWeek = moment().add(1, 'weeks').isoWeekday(dayINeed)
      }

      endOfWeek.set({ hour: 23, minute: 58, second: 0 })
      if (this.allowedDates.length >= 1) {
        if (endOfWeek.format('YYYY-MM-DD HH:mm:ss') !== this.allowedDates[0].endValue) {
          endOfWeek = this.allowedDates[0].endValue
        }
      }
      return endOfWeek
    },
    getDuration: function (start, end, item, selectedWeekStart = null) {
      // Check if attendance is a leave type
      if (item != null && item.leaveTypeId != null) {
        // Adjust the start and end dates to cover the full day
        start = moment.utc(start).startOf('day')
        end = moment.utc(end).endOf('day').add(1, 'second')

        const startDate = moment.utc(start)
        const endDate = moment.utc(end)

        // Variable to keep track of the number of holiday days in the selected week
        let holidayDaysInWeek = 0

        // Use selectedStart and calculate selectedEnd based on selectedStart
        selectedWeekStart = this.leaveStartDate(item)
        const selectedStart = moment(selectedWeekStart).startOf('day')
        // Reason for 4 days is to ensurwe that the holiday duration aligns correctly with a standard work week (Monday to Friday) without being affected by DST shifts
        const selectedEnd = selectedStart.clone().add(4, 'days')

        // Use the start date to index the days in the holiday period in UTC
        const indexDate = moment.utc(startDate)

        // Loop through every day in the holiday period
        while (indexDate.isSameOrBefore(endDate)) {
          // Check if the current day is a weekday (Monday to Friday) and if it falls within the selected week
          if (indexDate.day() >= 1 && indexDate.day() <= 5) {
            // Check if the date falls within the selected range
            if (indexDate.isBetween(selectedStart, selectedEnd, null, '[]')) {
              if (!endDate.isSame(indexDate, 'day')) {
                // Keep a count of the total holidays in the work
                holidayDaysInWeek++
              }
            }
          }

          // Move forward a day only if we haven't reached the end date
          indexDate.add(1, 'day')
        }

        const totalHours = holidayDaysInWeek * 24
        const totalMinutes = totalHours * 60

        const days = holidayDaysInWeek
        const hours = Math.floor((totalMinutes / 60) % 24)
        const minutes = totalMinutes % 60

        if (days > 0) {
          return `${days} days` // Just return the number of days as this is a holiday entry
        } else if (hours > 0) {
          return `${hours} hrs ${minutes} mins`
        } else {
          return `${minutes} mins`
        }
      } else {
        // Calculate the difference between the start and end dates
        const duration = moment.duration(moment(end).diff(moment(start)))
        // Return the days, hours and minutes for the leave
        const totalDays = duration.asDays()
        const days = Math.floor(totalDays)
        const hours = Math.floor((totalDays - days) * 24)
        const minutes = duration.minutes()

        if (days > 0) {
          return `${days} days ${hours} hrs ${minutes} mins`
        } else if (hours > 0) {
          return `${hours} hrs ${minutes} mins`
        } else {
          return `${minutes} mins`
        }
      }
    },
    // Get the time to display in the table
    getDisplayTime (item, type) {
      if (item.leaveTypeId != null) {
        return 'All Day'
      }

      const time = type === 'checkIn' ? item.checkInTime : item.checkOutTime

      // Ensure the time is valid before formatting by adding the local timezone
      return time ? moment.utc(time).local().format('HH:mm:ss') : 'N/A'
    },
    getTimesheet (showLoader) {
      const scope = this

      if (showLoader !== false) {
        this.loading = true
      }

      const endTime = this.selectedTimesheetsDate
      const params = {
        DurationEntry: 'WEEKLY',
        endDate: endTime
      }

      scope.$store.dispatch('timesheet/retrieveEmployeeTimesheets', params)
        .catch((err) => {
          console.log(err)
        }).finally(function () {
          scope.loading = false
        })
    },
    getDurationUnformatted (start, end) {
      if (start != null && end != null) {
        const duration = moment.duration(moment(end).diff(moment(start)))

        return duration.asDays()
      }
      return 0
    },
    isApprovedDisabled () {
      const scope = this
      const unCheckedOutTimesheet = this.employeeTimesheets.find(et => et.checkOutTime == null)
      const durationGreater = this.employeeTimesheets.find(et => et.leaveTypeId == null && scope.getDurationUnformatted(et.checkInTime, et.checkOutTime) >= 1)
      if (!this.isAllApproved || this.isSubmitted || unCheckedOutTimesheet || durationGreater || this.employeeTimesheets.length === 0) {
        return true
      }
      return false
    },
    isWeekApproved () {
      const scope = this
      var params = {
        endDate: scope.selectedTimesheetsDate
      }
      this.$store.dispatch('timesheet/isWeekApproved', params).then(function (approved) {
        scope.isApproved = approved
      })
    },
    async getTimesheetMetadataTypeLookups () {
      try {
        const params = {
          name: 'ATTENDANCE_METADATA'
        }
        const lookups = await this.$store.dispatch('lookups/getLookup', params)
        this.lookups = lookups
      } catch {
        this.$snotify.error('Failed to get timesheet lookups.')
      }
    },
    isWeekSubmitted () {
      const scope = this
      var params = {
        endDate: scope.selectedTimesheetsDate
      }
      scope.$store.dispatch('timesheet/isWeekSubmittedApproved', params)
        .then(function (data) {
          if (data !== true) {
            scope.isSubmitted = false
          } else {
            scope.isSubmitted = true
          }
        })
    },
    getApprover () {
      const scope = this
      var params = {
        endDate: scope.selectedTimesheetsDate
      }
      scope.$store.dispatch('timesheet/timesheetApprover', params)
    },

    /**
     * if the leave starts before the week of interest change the
     * displayed start to the start of the week
     * @param {object} attendance
     * @returns date
     */
    leaveStartDate (attendance) {
      const endDate = this.selectedTimesheetsDate
      const startDate = moment(endDate).subtract(4, 'day').toDate()

      if (moment(attendance.checkInTime).isBefore(startDate)) {
        return startDate
      }

      return attendance.checkInTime
    },

    /**
     * if the leave end is after the week of interest change the displayed
     * end to the end of the week
     * @param {object} attendance
     * @returns date
     */
    leaveEndDate (attendance) {
      const endDate = this.selectedTimesheetsDate
      if (moment(endDate).isBefore(attendance.checkOutTime)) {
        return endDate
      }

      return attendance.checkOutTime
    }
  },
  mounted () {

  },
  async created () {
    this.$store.dispatch('timesheet/retrieveAllowedDates')
    this.selectedTimesheetsDate = this.getEndOfWeek().format('YYYY-MM-DD HH:mm:ss')
    this.getClients()
    await this.getTimesheetMetadataTypeLookups()
  }
}
