/* eslint-disable import/no-duplicates */
import dataManager from '@/plugins/dataManager'
import geoLocation from '@/components/GeoLocation.vue'
import fitForWork from './fitForWork.vue'
import moment from 'moment'
import confirm from '../../components/confirm/confirm.vue'

export default {
  name: 'CheckIn',
  components: {
    'geo-location': geoLocation,
    'fit-for-work': fitForWork,
    confirm: confirm
  },
  data () {
    return {
      loading: false,
      location: {
        latitude: null,
        longitude: null
      },
      site: null,
      isLocationHandled: false,
      siteId: null,
      attendance: {
        jobId: null
      },
      fitForWork: false,
      latestAttendance: null,
      locationSent: false,
      status: null,
      currentTime: moment()
    }
  },
  computed: {
    // latestAttendance () {
    //   return this.$store.getters['attendance/latestAttendance']
    // },

    isCheckedIn () {
      if (this.latestAttendance) {
        if (this.latestAttendance.checkInTime && !this.latestAttendance.checkOutTime) {
          return true
        } else {
          return false
        }
      }

      return false
    }
  },

  watch: {
    async isLocationHandled () {
      this.performCheckInOut()
    },
    async latestAttendance () {
      this.performCheckInOut()
    }
  },

  methods: {
    updateCurrentTime () {
      this.currentTime = moment()
    },

    /**
     * handle user confirming fit to work
     */
    async confirmedFitForWork () {
      this.fitForWork = true
      this.performCheckInOut()
    },

    async performCheckInOut () {
      if (this.isLocationHandled != null && this.fitForWork && this.locationSent === false && this.loading === false) {
        const siteId = this.$route.params.siteId
        let secret = ''

        if (this.$route.params.secret != null) {
          secret = this.$route.params.secret
        }

        if (siteId) {
          if (this.latestAttendance != null && this.latestAttendance.checkInTime && !this.latestAttendance.checkOutTime) {
            await this.handleCheckOut(siteId, false, secret)
          } else if (this.fitForWork === true) {
            await this.handleCheckIn(siteId, secret)
          }
        }
      }
    },

    async locationHandled () {
      this.isLocationHandled = true
    },
    async getLatestAttendance () {
      this.loading = true
      try {
        const attendance = await this.$store.dispatch('attendance/getLatestAttendance')

        if (attendance) {
          if (attendance.fitForWork === 'Y' && attendance.checkOutTime == null) {
            this.fitForWork = true
          }

          this.getSite(attendance.jobId)
          this.latestAttendance = attendance
        } else {
          this.latestAttendance = null
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    /**
     * perform the check in at the site
     * @param {Guid} siteId we are checking into
     * @param {string} secret hashed date time the QR was generated
     */
    async handleCheckIn (siteId, secret) {
      this.loading = true

      const params = {
        checkInTime: null,
        checkInLatitude: this.location.latitude,
        checkInLongitude: this.location.longitude,
        checkOutTime: null,
        checkOutLatitude: null,
        checkOutLongitude: null,
        jobId: siteId,
        tumblerId: siteId,
        comment: '',
        qRCheckOut: false,
        qRCheckIn: true,
        fitForWork: this.fitForWork === true ? 'Y' : 'N',
        secret: secret
      }

      try {
        await this.$store.dispatch('checkIn/checkIn', params)
        this.locationSent = true
        this.status = 'CHECKED_IN'
        await this.getLatestAttendance()
        this.$router.replace({ name: 'CheckIn' })
      } catch (err) {
        this.$snotify.error('Error checking in, please contact support')
      } finally {
        this.loading = false
      }
    },

    async getSite (siteId) {
      try {
        if (siteId != null) {
          this.site = await this.$store.dispatch('site/getSiteForJob', siteId)
        }
      } catch (err) {
        this.$snotify.error(err.message)
      } finally {
        this.loading = false
      }
    },

    /**
     * perform the check out
     * @param {GUID} siteId we are checking out of
     * @param {bool} manual false if scanned
     * @param {string} secret hashed date and time the QR was generated
     */
    async handleCheckOut (siteId, manual, secret) {
      const confirm = await this.$refs.confirm.open('Checkout', 'Are you sure you want to checkout', { color: 'primary', confirm: 'Yes' })

      if (confirm) {
        this.loading = true

        if (manual == null) {
          manual = false
        }

        if (secret == null) {
          secret = ''
        }

        const params = {
          checkInTime: null,
          checkInLatitude: null,
          checkInLongitude: null,
          checkOutTime: null,
          checkOutLatitude: this.location.latitude,
          checkOutLongitude: this.location.longitude,
          jobId: siteId,
          tumblerId: siteId,
          comment: null,
          qRCheckOut: !manual,
          qRCheckIn: false,
          fitForWork: '',
          secret: secret
        }

        try {
          await this.$store.dispatch('checkIn/checkOut', params)
          await this.$store.dispatch('checkIn/updateDailyTotalsAfterCheckOut')
          this.locationSent = true
          this.status = 'CHECKED_OUT'
          await this.getLatestAttendance()
          this.$router.replace({ name: 'CheckIn' })
        } catch (err) {
          this.$snotify.error('Error checking out, please contact support')
        } finally {
          this.loading = false
        }
      }
    }
  },

  async created () {
    this.updateCurrentTime()
    setInterval(() => this.updateCurrentTime(), 1 * 1000)
    this.siteId = this.$route.params.siteId
    Object.freeze(this.siteId)
    await this.getLatestAttendance()
    await this.getSite(this.siteId)
  },

  async mounted () {
    dataManager.get('clive', 'v1/leaveManagement/user')
      .then((data) => {
        console.log(data)
      })
  }
}
